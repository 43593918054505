import React from "react";

export default function Boton({ handlerClick, text, loading }) {
  return (
    <button
      className="btn btn-ocre mt-3"
      type="button"
      disabled={loading}
      onClick={() => handlerClick()}
    >
      {loading ? (
        <span
          className="spinner-border spinner-border-sm"
          aria-hidden="true"
        ></span>
      ) : (
        <></>
      )}
      <span role="status">{loading ? " Cargando..." : text}</span>
    </button>
  );
}
