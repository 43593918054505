import React from "react";
import { premiosInstantaneosDescripcion } from "../lib/utils";
export default function ModalFinalConPremioV2({
  descripcion = "test",
  distribuidor,
  descripcion_canjeo,
}) {
  function abrirModalDescripcionCanjeo() {
    const myModalDescripcionCanjeo = new window.bootstrap.Modal(
      document.getElementById("modalDescripcionCanjeoV2")
    );
    myModalDescripcionCanjeo.show();
  }
  return (
    <>
      <div
        className="modal modal-lg fade"
        id="modalFinalConPremioV2"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <img
              src="/img/vectorModalTopInverse.png"
              className="modal-decoration-top"
            />
            <img
              src="/img/vectorModalBottomInverse.png"
              className="modal-decoration-bottom"
            />
            <img
              src="/img/panesLogoBlue.png"
              height="50px"
              className="modal-logo-panes"
            />
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Cerrar"
                onClick={() => window.location.reload()}
              ></button>
            </div>
            <div className="modal-body p-4">
              <div className="modal-contenido d-flex flex-column px-2 pb-3 px-md-5 pb-md-5 ">
                <h2 className="h2-titulo">
                  ¡Te ganaste uno de <br />
                  nuestros <span>premios instantaneos</span>!
                </h2>
                <div className="d-flex flex-column flex-md-row align-items-center justify-content-center text-start mt-4">
                  <div className="d-flex justify-content-end img-premio">
                    <img
                      src={premiosInstantaneosDescripcion[descripcion].imagen}
                      className="img-premio"
                    />
                  </div>
                  <div className="div-descripcion-premio px-4 d-flex flex-column justify-content-center text-center mt-3 mt-md-0">
                    <h2 className="h2-premio">{descripcion}</h2>
                    <p className="p-descripcion-premio">
                      {premiosInstantaneosDescripcion[descripcion].descripcion}
                    </p>
                  </div>
                </div>
                <button
                  className="btn btn-link bg-transparent"
                  onClick={abrirModalDescripcionCanjeo}
                >
                  Conocé aqui como canjearlo...
                </button>
                <div className="div-contenedor-botones mt-4 d-flex flex-column justify-content-center align-self-center">
                  <button
                    onClick={() => window.location.reload()}
                    className="btn d-block my-1 "
                  >
                    Quiero seguir sumando chances
                  </button>
                  <button
                    onClick={() =>
                      (window.location =
                        "https://www.lesaffreargentina.com.ar/")
                    }
                    className="btn d-block my-1 "
                  >
                    Volver a la pagina principal
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="modalDescripcionCanjeoV2"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <img
              src="/img/vectorModalTopInverse.png"
              className="modal-decoration-top"
            />
            <img
              src="/img/vectorModalBottomInverse.png"
              className="modal-decoration-bottom"
            />
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Cerrar"
              ></button>
            </div>
            <div className="modal-body p-4">
              <div className="modal-contenido d-flex flex-column px-2 pb-3 px-md-5 pb-md-5 ">
                <h2>Te ganaste un Premio de: {distribuidor}</h2>
                <p className="mt-2 mb-1">Te ganaste:</p>
                <h3 className="mt-0">{descripcion}</h3>
                <hr />
                <small>{descripcion_canjeo}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
