import React from "react";

export default function ModalFinal() {
  return (
    <div
      className="modal fade"
      id="modalFinal"
      tabIndex="-1"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <img
            src="/img/vectorModalTopInverse.png"
            className="modal-decoration-top"
          />
          <img
            src="/img/vectorModalBottomInverse.png"
            className="modal-decoration-bottom"
          />
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Cerrar"
              onClick={() => window.location.reload()}
            ></button>
          </div>
          <div className="modal-body p-4">
            <div className="modal-contenido d-flex flex-column px-2 pb-3 px-md-5 pb-md-5 ">
              <h2>
                ¡Ya estás participando!
              </h2>
              <p className="mt-4">
                Podes seguir sumando chances hasta el 31/12/2023
              </p>
              <button
                onClick={() => window.location.reload()}
                className="btn d-block my-1 "
              >
                Quiero seguir sumando chances
              </button>
              <button
                onClick={() =>
                  (window.location = "https://www.lesaffreargentina.com.ar/")
                }
                className="btn d-block my-1 "
              >
                Volver a la pagina principal
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
