import { ERROR_GENERICO } from "../utils";
import { apiUrl } from "./consts";

export function getUsuarioPorToken(form) {
  return new Promise((resolve, reject) => {
    try {
      fetch(apiUrl+"getUsuarioPorToken.php", {
        method: "POST",
        body: new URLSearchParams(form),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          resolve(data);
        });
    } catch (error) {
      resolve(ERROR_GENERICO);
    }
  });
}
