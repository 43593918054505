import { useState } from "react";
import Boton from "../components/Boton";
import CamposFormulario from "../components/CamposFormulario";
import { agregarUsuario } from "../lib/api/crearUsuario";
import {
  listadoDistribuidores,
  listadoProvincias,
  validarEmail,
} from "../lib/utils";

const campos = [
  {
    name: "nombre",
    placeholder: "Nombre",
    type: "text",
    colMd: 6,
    col: 12,
  },
  {
    name: "apellido",
    placeholder: "Apellido",
    type: "text",
    colMd: 6,
    col: 12,
  },
  {
    name: "telefono",
    placeholder: "Telefono",
    type: "tel",
    colMd: 6,
    col: 12,
  },
  {
    name: "nombre_panaderia",
    placeholder: "Nombre de la Panadería",
    type: "text",
    colMd: 6,
    col: 12,
  },
  {
    name: "provincia_panaderia",
    placeholder: "Seleccione la Provincia de su Panadería...",
    type: "select",
    colMd: 6,
    col: 12,
    options: listadoProvincias,
  },
  {
    name: "ciudad_panaderia",
    placeholder: "Ciudad de la Panadería",
    type: "text",
    colMd: 6,
    col: 12,
  },
  {
    name: "direccion_panaderia",
    placeholder: "Dirección de la Panadería",
    type: "address",
    colMd: 6,
    col: 12,
  },
  {
    name: "distribuidor",
    placeholder: "Seleccione su Distribuidor...",
    type: "select",
    colMd: 6,
    col: 12,
    options: listadoDistribuidores,
  },
];
const formInicial = campos.map((campo) => ({ [campo.name]: "" }));
export default function CargaDatosPersonalesPage({
  handlerCallback,
  handlerUsuario,
  usuario,
}) {
  const [form, setForm] = useState(formInicial);
  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(false);
  function volverInicio() {
    localStorage.removeItem("token");
    setForm(formInicial);
    handlerCallback("cargaEmail");
  }
  async function crearUsuario() {
    if (
      !form.nombre ||
      !form.apellido ||
      !form.telefono ||
      !form.nombre_panaderia ||
      !form.provincia_panaderia ||
      !form.ciudad_panaderia ||
      !form.direccion_panaderia ||
      !form.distribuidor
    ) {
      setErrorText("❌ Error: Debe ingresar todos los datos");
      return;
    }
    if (!usuario.email || !validarEmail(usuario.email)) {
      setErrorText("❌ Error: Email no valido");
      return;
    }
    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute("6LeJkFUoAAAAAE94tjyWPupAaJNW6BzdP5r3B4tJ", {
          action: "submit",
        })
        .then(async function (recaptchaToken) {
          setLoading(true);
          let resultado = await agregarUsuario({
            ...form,
            email: usuario.email,
            recaptchaToken: recaptchaToken,
          });
          setLoading(false);
          if (resultado.error) {
            setErrorText("❌ Error: " + resultado.error);
            return;
          }
          localStorage.setItem("token", resultado.datos.token);
          handlerUsuario(resultado.datos);
          handlerCallback("cargaDatosProductos");
        });
    });
  }
  return (
    <div className="form-container">
      <h2 className="text-white">
        Ingrese sus datos para poder participar del sorteo
      </h2>
      <h6 className="text-white pt-2 pb-4">
        ¡Deberás cargarlos solo una vez, la próxima ya no será necesario!
      </h6>
      <div className="d-flex flex-column">
        <input
          type="email"
          className="my-1 form-control"
          value={usuario.email}
          placeholder="Email"
          disabled
        />
        <CamposFormulario
          campos={campos}
          handlerChangeForm={setForm}
          handlerEnter={crearUsuario}
        />
        <Boton handlerClick={crearUsuario} text="Continuar" loading={loading} />        
        <label className="text-white fw-bold p-1 mt-2">{errorText}</label>
        <button
          type="button"
          className="btn btn-link mt-3 text-white"
          onClick={() => volverInicio()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-arrow-left me-1"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
            />
          </svg>
          Volver al Inicio
        </button>
      </div>
    </div>
  );
}
