import React from "react";
export default function ModalFinalConPremio({
  descripcion,
  distribuidor,
  descripcion_canjeo,
}) {
  function abrirModalDescripcionCanjeo() {
    const myModalDescripcionCanjeo = new window.bootstrap.Modal(
      document.getElementById("modalDescripcionCanjeo")
    );
    myModalDescripcionCanjeo.show();
  }
  return (
    <>
      <div
        className="modal fade"
        id="modalFinalConPremio"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <img
              src="/img/vectorModalTopInverse.png"
              className="modal-decoration-top"
            />
            <img
              src="/img/vectorModalBottomInverse.png"
              className="modal-decoration-bottom"
            />
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Cerrar"
                onClick={() => window.location.reload()}
              ></button>
            </div>
            <div className="modal-body p-4">
              <div className="modal-contenido d-flex flex-column px-2 pb-3 px-md-5 pb-md-5 ">
                <h2>¡Ganaste un Premio Instántaneo!</h2>
                <p className="mt-2 mb-1">Te ganaste:</p>
                <h3 className="mt-0">{descripcion}</h3>
                <button className="btn btn-link bg-transparent" onClick={abrirModalDescripcionCanjeo}>
                  Conocé aqui como canjearlo...
                </button>
                <hr className="my-4" />
                <h3>¡Además, ya sumaste chances para el Sorteo!</h3>
                <small className="my-2 text-white">
                  Seguí sumando chances hasta el 31/12/2023
                </small>
                <button
                  onClick={() => window.location.reload()}
                  className="btn d-block my-1 "
                >
                  Quiero seguir sumando chances
                </button>
                <button
                  onClick={() =>
                    (window.location = "https://www.lesaffreargentina.com.ar/")
                  }
                  className="btn d-block my-1 "
                >
                  Volver a la pagina principal
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="modalDescripcionCanjeo"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <img
              src="/img/vectorModalTopInverse.png"
              className="modal-decoration-top"
            />
            <img
              src="/img/vectorModalBottomInverse.png"
              className="modal-decoration-bottom"
            />
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Cerrar"
              ></button>
            </div>
            <div className="modal-body p-4">
              <div className="modal-contenido d-flex flex-column px-2 pb-3 px-md-5 pb-md-5 ">
                <h2>Te ganaste un Premio de: {distribuidor}</h2>
                <p className="mt-2 mb-1">Te ganaste:</p>
                <h3 className="mt-0">{descripcion}</h3>
                <hr />
                <small>{descripcion_canjeo}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
