import logo from "./logo.svg";
import "./App.css";
import { useState } from "react";
import CargaEmailPage from "./pages/CargaEmailPage";
import CargaDatosPersonalesPage from "./pages/CargaDatosPersonalesPage";
import CargaDatosProductosPage from "./pages/CargaDatosProductosPage";

const paginas = {
  cargaEmail: CargaEmailPage,
  cargaDatosPersonales: CargaDatosPersonalesPage,
  cargaDatosProductos: CargaDatosProductosPage,
};
function App() {
  const [pagina, setPagina] = useState({ actual: paginas["cargaEmail"] });
  const [usuario, setUsuario] = useState(null);
  function handlerCallback(pagina) {
    setPagina({ actual: paginas[pagina] });
  }
  return (
    <div className="App">
      <img className="logo-top" src="/img/panesLogo.png" />
      <div className="contenedor-paginas d-flex flex-column justify-content-center align-items-center h-100 text-center px-4">
        <pagina.actual
          handlerCallback={handlerCallback}
          handlerUsuario={setUsuario}
          usuario={usuario}
        />
      </div>
      <img className="logo-bottom" src="/img/lesaffre-h75-white.png" />
    </div>
  );
}

export default App;
