import React, { useEffect, useState } from "react";

export default function CamposFormulario({
  campos = [],
  handlerChangeForm,
  handlerEnter,
}) {
  const [form, setForm] = useState({});
  useEffect(() => {
    handlerChangeForm(form);
  }, [form]);
  return (
    <div className="row">
      {campos.map((campo, i) => (
        <div
          className={"col-" + campo.col + " col-md-" + campo.colMd + " my-1"}
          key={campo.name}
        >
          {campo.type === "select" ? (
            <select
              defaultValue={""}
              onChange={(e) =>
                setForm({ ...form, [campo.name]: e.target.value })
              }
              className="form-select my-1"
            >
              <option value="">{campo.placeholder}</option>
              {campo.options.map((opcion, i) => (
                <option key={opcion.id} value={opcion.id}>
                  {opcion.nombre}
                </option>
              ))}
            </select>
          ) : (
            <input
              name={campo.name}
              type={campo.type}
              placeholder={campo.placeholder}
              onChange={(e) =>
                setForm({ ...form, [campo.name]: e.target.value })
              }
              className="form-control my-1"
              onKeyUp={(e) => {
                if (e.key === "Enter" && i === campos.length - 1) {
                  handlerEnter();
                }
              }}
            />
          )}
        </div>
      ))}
    </div>
  );
}
