import { useEffect, useState } from "react";
import Boton from "../components/Boton";
import CamposFormulario from "../components/CamposFormulario";
import { getUsuarioPorToken } from "../lib/api/revisarToken";
import { getUsuarioPorEmail } from "../lib/api/validarEmail";
import { validarEmail } from "../lib/utils";
const campos = [
  { type: "email", name: "email", placeholder: "Ingrese aquí su Email...", col: 12, colMd: 12 },
];
const formInicial = campos.map((campo) => ({ [campo.name]: "" }));
export default function CargaEmailPage({
  handlerCallback,
  handlerUsuario,
  usuario,
}) {
  const [form, setForm] = useState(formInicial);
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState("");
  useEffect(() => {
    setErrorText("");
    revisarToken();
  }, []);
  async function revisarToken() {
    if (localStorage.getItem("token")) {
      window.grecaptcha.ready(function () {
        window.grecaptcha
          .execute("6LeJkFUoAAAAAE94tjyWPupAaJNW6BzdP5r3B4tJ", {
            action: "submit",
          })
          .then(async function (recaptchaToken) {
            let resultado = await getUsuarioPorToken({
              token: localStorage.getItem("token"),
              recaptchaToken: recaptchaToken,
            });
            if (!resultado.error) {
              localStorage.setItem("token", resultado.datos.token);
              handlerUsuario(resultado.datos);
              handlerCallback("cargaDatosProductos");
            }
          });
      });
    }
  }
  async function cargarEmail() {
    if (!form.email) {
      setErrorText("❌ Error: Debe ingresar Email");
      return;
    }
    if (!validarEmail(form.email)) {
      setErrorText("❌ Error: Email no válido");
      return;
    }
    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute("6LeJkFUoAAAAAE94tjyWPupAaJNW6BzdP5r3B4tJ", {
          action: "submit",
        })
        .then(async function (recaptchaToken) {
          setLoading(true);
          let resultado = await getUsuarioPorEmail({
            ...form,
            recaptchaToken: recaptchaToken,
          });
          setLoading(false);
          if (resultado.error) {
            if (resultado.error === "No hay usuario con ese email") {
              handlerUsuario({ email: form.email });
              handlerCallback("cargaDatosPersonales");
              return;
            }
            setErrorText("❌ Error: " + resultado.error);
            return;
          } else {
            localStorage.setItem("token", resultado.datos.token);
            handlerUsuario(resultado.datos);
            handlerCallback("cargaDatosProductos");
          }
        });
    });
  }

  return (
    <div>
      <h2 className="text-white">¡Sumá chances para hacer crecer tu negocio!</h2>
      <h6 className="text-white pt-2 pb-4">
        Ingresá tu email para empezar a participar
      </h6>

      <div className="d-flex flex-column mx-0 mx-md-5 px-md-4 px-0">
        <CamposFormulario
          campos={campos}
          handlerChangeForm={setForm}
          handlerEnter={cargarEmail}
        />
        <Boton handlerClick={cargarEmail} text="Continuar" loading={loading} />
        <p className="text-white mt-2">Al hacer click en continuar, usted acepta los <a href="/terminos.pdf" target="_blank" className="text-white"><b>Términos y Condiciones</b></a></p>
        <label className="text-white fw-bold p-1 mt-2">{errorText}</label>
      </div>
    </div>
  );
}
