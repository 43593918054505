import { useEffect, useState } from "react";
import Boton from "../components/Boton";
import CamposFormulario from "../components/CamposFormulario";
import ModalFinal from "../components/ModalFinal";
import ModalFinalConPremio from "../components/ModalFinalConPremio";
import ModalFinalConPremioV2 from "../components/ModalFinalConPremioV2";
import { cargarChance } from "../lib/api/cargarChance";

const campos = [
  {
    name: "nro_lote",
    placeholder: "Ingresá el Nro de Lote... Ejemplo: (4F-298-247-B)",
    type: "text",
    col: 12,
    colMd: 12,
  },
  {
    name: "hora",
    placeholder: "Ingresa la Hora... Ejemplo: (12:00:00)",
    type: "text",
    col: 12,
    colMd: 12,
  },
];
const formInicial = campos.map((campo) => ({ [campo.name]: "" }));
export default function CargaDatosProductosPage({
  handlerCallback,
  handlerUsuario,
  usuario,
}) {
  const [form, setForm] = useState(formInicial);
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [datosPremio, setDatosPremio] = useState(null);
  /* TEST MODAL CON PREMIO V2 */
  /*
  useEffect(() => {
    setDatosPremio({
      descripcion: "Bandeja para Panadería",
      descripcion_canjeo:
        "El distribuidor te llevará tu regalo en la proxima entrega de productos!",
      distribuidor: "DISTRICONF S.R.L.",
    });
    const myModalFinalConPremioV2 = new window.bootstrap.Modal(
      document.getElementById("modalFinalConPremioV2")
    );
    myModalFinalConPremioV2.show();
  }, []);
  */
  /* FIN TEST */

  function resetearUsuario() {
    localStorage.removeItem("token");
    handlerCallback("cargaEmail");
  }
  async function cargarChanceNueva() {
    if (!form.nro_lote || !form.hora) {
      setErrorText("❌ Error: Debe ingresar Nro de Lote y Hora");
      return;
    }
    if (!usuario.id || !localStorage.getItem("token")) {
      resetearUsuario();
      return;
    }
    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute("6LeJkFUoAAAAAE94tjyWPupAaJNW6BzdP5r3B4tJ", {
          action: "submit",
        })
        .then(async function (recaptchaToken) {
          setLoading(true);
          let resultado = await cargarChance({
            ...form,
            usuario_id: usuario.id,
            token: localStorage.getItem("token"),
            recaptchaToken: recaptchaToken,
          });
          setLoading(false);
          if (resultado.error) {
            setErrorText("❌ Error: " + resultado.error);
            return;
          }
          if (resultado.datos) {
            if (resultado.datos.premio) {
              console.log(resultado.datos.premio);
              setErrorText("✔️ Datos cargados exitosamente");
              setForm(formInicial);
              setDatosPremio(resultado.datos.premio);
              const myModalFinalConPremioV2 = new window.bootstrap.Modal(
                document.getElementById("modalFinalConPremioV2")
              );
              myModalFinalConPremioV2.show();
            } else {
              setErrorText("✔️ Datos cargados exitosamente");
              setForm(formInicial);
              const myModalFinal = new window.bootstrap.Modal(
                document.getElementById("modalFinal")
              );
              myModalFinal.show();
            }
          } else {
            setErrorText(
              "❌ Error al cargar el producto, intentelo nuevamente"
            );
            return;
          }
        });
    });
  }
  return (
    <div>
      <h2 className="text-white">
        Ingresá el Nro de Lote y Hora para participar
      </h2>
      <h6 className="text-white pt-2 pb-4">
        ¡Los podrás encontrar en la parte inferior del producto!
      </h6>

      <div className="d-flex flex-column mx-0 mx-md-5 px-md-4 px-0">
        <CamposFormulario
          campos={campos}
          handlerChangeForm={setForm}
          handlerEnter={cargarChanceNueva}
        />
        <Boton
          handlerClick={cargarChanceNueva}
          text="¡Participar Ahora!"
          loading={loading}
        />
        <label className="text-white fw-bold p-1 mt-2">{errorText}</label>
        <div className="mt-4 text-white">
          <p className="mb-2">
            Estas participando como
            <b>{" " + usuario.nombre + " " + usuario.apellido}</b> -
            <i> ({usuario.email})</i>
          </p>
          {
            usuario.chances_acumuladas > 0 ?
              <p className="mb-2">Llevás {usuario.chances_acumuladas} {usuario.chances_acumuladas === 1 ? "chance" : "chances"} acumuladas</p> :
              <></>
          }
          <button
            onClick={() => resetearUsuario()}
            className="btn btn-sm btn-outline-light"
          >
            No soy yo, volver al inicio
          </button>
        </div>
      </div>
      <ModalFinal />
      <ModalFinalConPremio {...datosPremio} />
      <ModalFinalConPremioV2 {...datosPremio} />
    </div>
  );
}
