export const ERROR_GENERICO = { datos: { error: "ERROR" } };

export function validarEmail(email) {
  if (!email.endsWith(".con")) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  return false;
}

export const listadoProvincias = [
  { id: "02", nombre: "CABA"},
  { id: "06", nombre: "GBA"},  
]

export const listadoProvinciasFull = [
  { id: "54", nombre: "Misiones" },
  { id: "74", nombre: "San Luis" },
  { id: "70", nombre: "San Juan" },
  { id: "30", nombre: "Entre Ríos" },
  { id: "78", nombre: "Santa Cruz" },
  { id: "62", nombre: "Río Negro" },
  { id: "26", nombre: "Chubut" },
  { id: "14", nombre: "Córdoba" },
  { id: "50", nombre: "Mendoza" },
  { id: "46", nombre: "La Rioja" },
  { id: "10", nombre: "Catamarca" },
  { id: "42", nombre: "La Pampa" },
  { id: "86", nombre: "Santiago del Estero" },
  { id: "18", nombre: "Corrientes" },
  { id: "82", nombre: "Santa Fe" },
  { id: "90", nombre: "Tucumán" },
  { id: "58", nombre: "Neuquén" },
  { id: "66", nombre: "Salta" },
  { id: "22", nombre: "Chaco" },
  { id: "34", nombre: "Formosa" },
  { id: "38", nombre: "Jujuy" },
  { id: "02", nombre: "Ciudad Autónoma de Buenos Aires" },
  { id: "06", nombre: "Buenos Aires" },
  { id: "94", nombre: "Tierra del Fuego, Antártida e Islas del Atlántico Sur" },
].sort((a, b) => a.nombre.localeCompare(b.nombre));

export const listadoDistribuidores = [
  { id: "1", nombre: "ANCHIPE JOSE LUIS" },
  { id: "2", nombre: "DISTRIBUIDORA DC S.R.L." },
  { id: "3", nombre: "DISTRIBUIDORA FLIA TOME SRL" },
  { id: "4", nombre: "DISTRICONF S.R.L." },
  { id: "5", nombre: "Ficer Sergio Daniel" },
  { id: "6", nombre: "Finocchio Ruben Dario" },
  { id: "7", nombre: "GARCIA EDGARDO LUCIO" },
  { id: "8", nombre: "Granel San Nicolas SRL" },
  { id: "9", nombre: "HAURE MARIA DE LOS ANGELES" },
  { id: "10", nombre: "LAGOSTENA DANIEL OMAR" },
  { id: "11", nombre: "LIMA OMAR Y LIMA LUCAS SH" },
  { id: "12", nombre: "MARJUANC SRL" },
  { id: "13", nombre: "MIGUEL ANGEL CENTENO" },
  { id: "14", nombre: "Ottaviano Leonardo Sergio" },
  { id: "15", nombre: "Parada Oscar - Bermudez Sergio -" },
  { id: "16", nombre: "Vazquez Celestino Alberto" },
  { id: "17", nombre: "Vazquez Sergio Gabriel" },
  { id: "18", nombre: "REPOSMAR S.A." },
  { id: "19", nombre: "10 DE OCTUBRE SRL" },
  { id: "20", nombre: "Corapi Matias Pascual" },
  { id: "21", nombre: "Harinas Ventas y Logística MDQ SRL" },
  { id: "22", nombre: "ENVASES DORE SRL" },
  { id: "23", nombre: "DOMINA FABIAN" },
  { id: "24", nombre: "DISTRIBUIDORA LA PAZ" },
  { id: "25", nombre: "TERSCIU" },
  { id: "26", nombre: "DELITE" },
  { id: "27", nombre: "Distribuidora Ronchi" },
];

export const premiosInstantaneosDescripcion = {
  "test": {
    imagen: "/img/guantesCircle.png",
  },
  "Guantes de Horno": {
    imagen: "/img/guantesCircle.png",
    descripcion:
      "Guantes profesionales para alta temperatura. El artículo cuenta con protección frente a altas temperaturas. Ofrece protección para el antebrazo. Apto para la manipulación de piezas metálicas calientes.",
  },
  "Pinzas de Panadería Acero": {
    imagen: "/img/pinzasCircle.png",
    descripcion:
      "Pinza de acero premium reforzada. Ideal para panaderías/restaurants/parrilla.",
  },
  Delantal: {
    imagen: "/img/delantalCircle.png",
    descripcion: "Diseñado para vos.",
  },
  "Bandeja para Panadería": {
    imagen: "/img/bandejaCircle.png",
    descripcion: "Placa de aluminio 46x29x2 cm. Bandeja de horno para exhibir.",
  },
  "1 Remera Levasaf": {
    imagen: "/img/remeraLesafCircle.png",
    descripcion: "",
  },
  "1 Remera Levina": {
    imagen: "/img/remeraLevinaCircle.png",
    descripcion: "",
  },
  "De Descuento en Productos Lesaffre": {
    imagen: "/img/10Circle.png",
    descripcion: "Canjealo en tu distribuidor",
  },
};
